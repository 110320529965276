import React, { useState, useEffect } from "react"

import { Map, TileLayer, Marker } from "react-leaflet"

import Layout from "../components/layout"
import SEO from "../components/seo"

import fetchJsonp from "fetch-jsonp"

import "url-search-params-polyfill"

var isBase64 = require("is-base64")

var Scroll = require("react-scroll")
var Element = Scroll.Element
var scroller = Scroll.scroller

require("es6-promise").polyfill()

function calculateSalary(
  Currency,
  SalaryFrom,
  SalaryTo,
  SalaryType,
  SalaryPeriod
) {
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  let NewSalary
  let NewSalaryFrom
  let NewSalaryTo

  let currencyFormatted = Currency

  if (Currency === "&pound;") {
    currencyFormatted = "£"
  }

  if (SalaryTo === 0) {
    NewSalary = SalaryType
  } else if (SalaryFrom === SalaryTo) {
    if (SalaryFrom >= 1000) {
      // Remove decimals
      NewSalaryFrom = currencyFormatted + numberWithCommas(SalaryFrom.toFixed())
    } else {
      // Add two decimals
      NewSalaryFrom =
        currencyFormatted + numberWithCommas(SalaryFrom.toFixed(2))
    }
    NewSalary = NewSalaryFrom + " " + SalaryPeriod
  } else {
    if (SalaryFrom >= 1000) {
      NewSalaryFrom = currencyFormatted + numberWithCommas(SalaryFrom.toFixed())
    } else {
      NewSalaryFrom =
        currencyFormatted + numberWithCommas(SalaryFrom.toFixed(2))
    }
    if (SalaryTo >= 1000) {
      NewSalaryTo = currencyFormatted + numberWithCommas(SalaryTo.toFixed())
    } else {
      NewSalaryTo = currencyFormatted + numberWithCommas(SalaryTo.toFixed(2))
    }

    NewSalary = NewSalaryFrom + " - " + NewSalaryTo + " " + SalaryPeriod
  }
  return NewSalary
}

function CheckJobHasNotExpired(JSONDate) {
  if (JSONDate) {
    let expDate = new Date(parseInt(JSONDate.substr(6), 10))
    let now = new Date()

    if (expDate > now) {
      return true
    }

    return false
  } else {
    return false
  }
}

function FormatDateTime(JSONDate) {
  if (JSONDate) {
    // Set default values to avoid function breaking
    var expDate = new Date(parseInt(JSONDate.substr(6)))
    var dateFormatOptions = new Object({})
    dateFormatOptions.weekday = "long"
    dateFormatOptions.year = "numeric"
    dateFormatOptions.month = "short"
    dateFormatOptions.day = "numeric"
    return expDate.toLocaleDateString(undefined, dateFormatOptions)
  } else {
    return JSONDate
  }
}

function generateGoogleJobsSchema(vacancy) {
  return {
    "@context": "http://schema.org",
    "@type": "JobPosting",
    baseSalary: calculateSalary(
      vacancy.Currency,
      vacancy.SalaryFrom,
      vacancy.SalaryTo,
      vacancy.SalaryType,
      vacancy.SalaryPeriod
    ),
    jobBenefits: vacancy.Benefits,
    datePosted: FormatDateTime(vacancy.PostingDate),
    validThrough: FormatDateTime(vacancy.ExpiryDate),
    description: vacancy.Description,
    employmentType: vacancy.JobTypeTime,
    industry: vacancy.Industry,
    jobLocation: {
      "@type": "Place",
      geo: {
        longitude: vacancy.Longitude,
        latitude: vacancy.Latitude,
        "@type": "GeoCoordinates",
      },
      address: {
        "@type": "PostalAddress",
        addressCountry: vacancy.Country,
        addressLocality: vacancy.Location,
        addressRegion: vacancy.Region,
        postalCode: vacancy.PostCode,
      },
    },
    occupationalCategory: vacancy.JobCategory,
    salaryCurrency: vacancy.Currency === "&pound;" ? "GBP" : "EUR",
    hiringOrganization: {
      "@type": "Organization",
      name: vacancy.Company,
      logo: vacancy.CompanyLogoPath,
    },
    title: vacancy.JobTitle,
    workHours: vacancy.JobTypeTime,
  }
}

const listenForIFrameHeight = function listenForIFrameHeight() {
  if (typeof window !== "undefined") {
    window.addEventListener(
      "message",
      function (event) {
        let careersPageiFrame = null

        if (document.getElementById("vf_iFrame_application_form")) {
          careersPageiFrame = document.getElementById(
            "vf_iFrame_application_form"
          )
        }

        if (
          careersPageiFrame !== null &&
          careersPageiFrame !== undefined &&
          careersPageiFrame !== "undefined"
        ) {
          let careersPageEvent = event.data[0]
          let careersPageHeight = event.data[1]

          switch (careersPageEvent) {
            case "setCareersPageHeight":
              let careersPageHeightExtra = careersPageHeight + 50

              console.log("cp height")
              console.log(careersPageHeightExtra)

              careersPageiFrame.height = careersPageHeightExtra
              break
          }
        }
      },
      false
    )
  }
}

const SmartfeedIndividual = ({ id = 1, vacancyTitlePath = false }) => {
  const [vacancy, setVacancy] = useState([])
  const [showApplication, setshowApplication] = useState(false)
  const [vacancyTitle, setVacancyTitle] = useState("Loading vacancy....")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    listenForIFrameHeight()
    setshowApplication(false)
    const timeOut = setTimeout(() => {
      if (isBase64(id) && vacancyTitlePath) {
        fetchVacancy(new Buffer(id, "base64").toString())
      } else {
        fetchVacancy(id)
      }
    }, 50)
    return () => clearTimeout(timeOut)
  }, [id])

  function vfScrollToElement(element, to, duration) {
    if (duration <= 0) return
    var difference = to - element.scrollTop
    var perTick = (difference / duration) * 10

    setTimeout(function () {
      element.scrollTop = element.scrollTop + perTick
      if (element.scrollTop === to) return
      window.scrollTo(element, to, duration - 10)
    }, 10)
  }

  function activateApplicationForm(setting) {
    setshowApplication(setting)

    scroller.scrollTo("ApplicationScrollTarget", {
      duration: 500,
      delay: 0,
      smooth: true,
      offset: -150,
    })
  }

  const fetchVacancy = async (id) => {
    setLoading(true)
    try {
      fetchJsonp("https://sf.vacancy-filler.co.uk/CareerPage/GetItem?id=" + id)
        .then(function (response) {
          return response.json()
        })
        .then(function (json) {
          const responseVacancy = json
          const resultVacancy = responseVacancy
          setLoading(false)
          setVacancy(resultVacancy)
          setVacancyTitle(resultVacancy.JobTitle)
          return resultVacancy
        })
        .catch(function (ex) {
          return fetchJsonp(
            `https://sf.vacancy-filler.co.uk/CareerPage/GetItem?id=${new Buffer(
              id,
              "base64"
            ).toString()}`
          )
            .then(function (response) {
              return response.json()
            })
            .then(function (json) {
              const responseVacancy = json
              const resultVacancy = responseVacancy
              setLoading(false)
              setVacancy(resultVacancy)
              setVacancyTitle(resultVacancy.JobTitle)
              return resultVacancy
            })
            .catch(function (ex) {
              setLoading(false)
              console.log(ex, "error")
            })
        })

      /*  
     JSON method, sadly doesn't work with SF
     const fetchingVacancy = await fetch(
        `https://sf.vacancy-filler.co.uk/CareerPage/GetItem?callback=gatsby&id=${id}`
      )
      const responseVacancy = await fetchingVacancy.json()
      const resultVacancy = await responseVacancy
      setLoading(false)
      setVacancy(resultVacancy)
      return resultVacancy 
      */
    } catch (error) {
      setLoading(false)
      console.log(error.message, "error")
    }
  }

  function grabImageEndFromQuery(region) {
    var urlParams = new URLSearchParams(window.location.search)
    var entries = urlParams.entries()
    let cpQueryString = {}
    for (let pair of entries) {
      cpQueryString[pair[0]] = pair[1]
    }

    if (
      region &&
      region !== "loading" &&
      (!cpQueryString || !cpQueryString.company)
    ) {
      let jobCardRegionNoSpaces = region.replace(/[, ]+/g, "-")

      if (
        jobCardRegionNoSpaces !== "Cheddar-Gorge-&-Caves" &&
        jobCardRegionNoSpaces !== "Longleat" &&
        jobCardRegionNoSpaces !== "Longleat-Forestry"
      ) {
        return "Longleat-Default"
      }

      if (jobCardRegionNoSpaces === "Cheddar-Gorge-&-Caves") {
        return "Cheddar-Gorge-and-Caves"
      }

      return jobCardRegionNoSpaces
    } else if (cpQueryString && cpQueryString.company) {
      return cpQueryString.company
    } else {
      return "Longleat-Default"
    }
  }

  return (
    <Layout>
      <SEO title={loading ? "Loading vacancy..." : vacancyTitle} />

      <div id="vacancyPageWrap" className="vacancy-item">
        <div className="app-hero-wrapper app-hero-wrapper-mid app-hero-wrapper-vacancy-page">
          {vacancy && vacancy != [] && vacancy.Region ? (
            <div
              className={
                "app-hero-background-image " + grabImageEndFromQuery("loading")
              }
            ></div>
          ) : (
            <div
              className={
                "app-hero-background-image " +
                grabImageEndFromQuery(vacancy.Region)
              }
            ></div>
          )}
          <div className="app-hero-background-overlay"></div>
          <div className="app-hero-content-center">
            <div className="container"></div>
            <div className="app-hero-button-wrap"></div>
          </div>
          <div className="app-hero-content-bottom"></div>
        </div>

        {!vacancy ||
          vacancy == [] ||
          (!vacancy.Description && (
            <div className="app-content-content">
              <div className="container"></div>
            </div>
          ))}

        {vacancy && vacancy != [] && vacancy.Description && (
          <>
            {vacancy.PostingDate &&
              vacancy.ExpiryDate &&
              CheckJobHasNotExpired(vacancy.ExpiryDate) && (
                <script
                  type="application/ld+json"
                  dangerouslySetInnerHTML={{
                    __html: JSON.stringify(generateGoogleJobsSchema(vacancy)),
                  }}
                />
              )}

            <div className="app-content-content">
              <div className="container">
                <div id="scriptsWrap"></div>

                <div className="row vacancy-individual-wrap">
                  <div className="col-12">
                    <div className="jobtitle-wrap">
                      {!vacancy ||
                        vacancy == [] ||
                        (!vacancy.Description && <h1>Loading vacancy...</h1>)}
                      {vacancy && vacancy != [] && vacancy.Description && (
                        <h1>{vacancyTitle}</h1>
                      )}
                    </div>
                    <div className="jobdetails-wrap"></div>
                    <Element name="ApplicationScrollTarget"></Element>
                  </div>
                  <div className="col-12 col-8-l col-lg-8 col-sm-12">
                    <div className="vd_wrapper page-content-inner">
                      <div className="vd_main_wrapper">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            {showApplication && (
                              <div className="vf_iFrame_wrapper">
                                <iframe
                                  id="vf_iFrame_application_form"
                                  src={
                                    vacancy.Link +
                                    "&action=ApplyNow&HideCareersLink=true"
                                  }
                                />
                              </div>
                            )}

                            {!showApplication && (
                              <div className="vf-JobDescription">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: vacancy.Description,
                                  }}
                                />
                              </div>
                            )}
                            <hr />
                            <div className="vf-JobActions">
                              {vacancy.ExpiryDate &&
                              CheckJobHasNotExpired(vacancy.ExpiryDate) ? (
                                <div className="mb-4 vf-ApplyButton-bottom">
                                  {!showApplication && (
                                    <button
                                      className="btn btn-primary btn-fluid"
                                      onClick={() => {
                                        activateApplicationForm(true)
                                      }}
                                    >
                                      Apply now
                                    </button>
                                  )}
                                  {showApplication && (
                                    <button
                                      className="btn btn-secondary btn-fluid"
                                      onClick={() => {
                                        activateApplicationForm(false)
                                      }}
                                    >
                                      Go back to job description
                                    </button>
                                  )}
                                </div>
                              ) : (
                                <p>This vacancy has expired</p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-4-l col-lg-4 col-sm-12">
                    <div className="vd_sidebar_wrap">
                      <div className="content">
                        <div className="heading">
                          <h3>Job Summary</h3>
                        </div>

                        <div className="vf-details mt-2">
                          <div className="item">
                            <span>Reference: </span>
                            <span className="vf-AdvertReference">
                              {vacancy.AdvertReference}
                            </span>
                          </div>
                          <div className="item">
                            <span>Category: </span>
                            <span className="vf-category">
                              {vacancy.JobCategory}
                            </span>
                          </div>
                          <div className="item">
                            <span>Location: </span>
                            <span className="vf-Location">
                              {vacancy.Location}
                            </span>
                          </div>
                          <div className="item">
                            <span>Region: </span>
                            <span className="vf-region">{vacancy.Region}</span>
                          </div>
                          <div className="item">
                            <span>Contract Type: </span>
                            <span className="vf-JobType">
                              {vacancy.JobType}
                            </span>
                          </div>
                          <div className="item">
                            <span>Contract Time: </span>
                            <span className="vf-JobTypeTime">
                              {vacancy.JobTypeTime}
                            </span>
                          </div>
                          <div className="item">
                            <span>Salary: </span>
                            <span className="vf-Salary">
                              {calculateSalary(
                                vacancy.Currency,
                                vacancy.SalaryFrom,
                                vacancy.SalaryTo,
                                vacancy.SalaryType,
                                vacancy.SalaryPeriod
                              )}
                            </span>
                          </div>
                          <div className="item">
                            <span>Expiry Date: </span>
                            <span className="vf-ExpiryDate">
                              {FormatDateTime(vacancy.ExpiryDate)}
                            </span>
                          </div>
                        </div>

                        {vacancy.Attachments &&
                          vacancy.Attachments != [] &&
                          vacancy.Attachments.length >= 0 && (
                            <ul className="vf-Attachments">
                              {vacancy.Attachments &&
                                vacancy.Attachments.map((attachment, i) => (
                                  <li id={attachment.S3Name} className="row">
                                    <a
                                      className="btn-filedownload vf-row"
                                      href={attachment.Location}
                                      target="_blank"
                                    >
                                      <span className="filetype filetype_icon vf-col-xs-2">
                                        <i className="file outline icon"></i>
                                      </span>
                                      <span className="details vf-col-xs-8">
                                        <span className="filename">
                                          {attachment.FileName}
                                        </span>
                                      </span>
                                    </a>
                                  </li>
                                ))}
                            </ul>
                          )}

                        <div className="sidebar-buttons">
                          {vacancy.ExpiryDate &&
                          CheckJobHasNotExpired(vacancy.ExpiryDate) ? (
                            <div className="vf-ApplyButton-sidebar">
                              {!showApplication && (
                                <button
                                  className="btn btn-primary btn-fluid"
                                  onClick={() => {
                                    activateApplicationForm(true)
                                  }}
                                >
                                  Apply now
                                </button>
                              )}
                              {showApplication && (
                                <button
                                  className="btn btn-secondary btn-fluid"
                                  onClick={() => {
                                    activateApplicationForm(false)
                                  }}
                                >
                                  Go back to job description
                                </button>
                              )}
                            </div>
                          ) : (
                            <p>This vacancy has expired.</p>
                          )}

                          <a
                            href="/vacancies"
                            className="mb-2 btn btn-secondary d-block ui button button-secondary"
                            id="vacancy-page-back-search-button"
                          >
                            Back to vacancies
                          </a>
                        </div>

                        {vacancy.Latitude != "" &&
                          vacancy.Latitude != null &&
                          vacancy.Latitude != 0 && (
                            <Map
                              center={[vacancy.Latitude, vacancy.Longitude]}
                              zoom="12"
                              pitch="0"
                              bearing="0"
                              scrollZoom="false"
                              pitchWithRotate="false"
                              dragRotate="false"
                            >
                              <TileLayer url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png" />
                              <Marker
                                position={[vacancy.Latitude, vacancy.Longitude]}
                              />
                            </Map>
                          )}

                        <hr />
                        <p className="vf-SocialButtons-title">
                          <strong className="d-block">
                            Position not right for you?
                          </strong>
                        </p>
                        <p className="vf-SocialButtons-subtitle">
                          Share it with someone you know
                        </p>
                        <div id="share-icons">
                          <div className="vf-SocialButtons">
                            <a
                              data-sharelinkend=""
                              href={
                                "https://www.facebook.com/sharer/sharer.php?u=" +
                                "https://jobs.longleat.co.uk/vacancies/vacancy/" +
                                encodeURIComponent(vacancy.Id) +
                                "%26ExtraData=%26utm_source=social%26utm_campaign=sharing_buttons"
                              }
                              target="_blank"
                              rel="nofollow"
                              className="vf-facebook-sharing-button vf-shareButton vf-activate-sharing-button"
                            >
                              <i className="icon facebook"></i>
                            </a>
                            <a
                              href={
                                "https://twitter.com/intent/tweet?text=" +
                                vacancyTitle +
                                " - " +
                                "https://jobs.longleat.co.uk/vacancies/vacancy/" +
                                encodeURIComponent(vacancy.Id) +
                                "%26ExtraData=%26utm_source=social%26utm_campaign=sharing_buttons"
                              }
                              target="_blank"
                              rel="nofollow"
                              className=" vf-twitter-sharing-button vf-shareButton vf-activate-sharing-button"
                            >
                              <i className="icon twitter"></i>
                            </a>
                            <a
                              href={
                                "https://www.linkedin.com/shareArticle?mini=true&url=" +
                                "https://jobs.longleat.co.uk/vacancies/vacancy/" +
                                encodeURIComponent(vacancy.Id) +
                                "%26ExtraData=%26utm_source=social%26utm_campaign=sharing_buttons" +
                                "&title=" +
                                vacancyTitle +
                                "&summary=&source="
                              }
                              target="_blank"
                              rel="nofollow"
                              className=" vf-linkedin-sharing-button vf-shareButton vf-activate-sharing-button"
                            >
                              <i className="icon linkedin"></i>
                            </a>
                            <a
                              href={
                                "mailto:?&subject=Check out this vacany&body=Check out this " +
                                vacancy.JobTitle +
                                " vacancy " +
                                " - https://jobs.longleat.co.uk/vacancies/vacancy/" +
                                encodeURIComponent(vacancy.Id) +
                                "%26ExtraData=%26utm_source=social%26utm_campaign=sharing_buttons"
                              }
                              target="_blank"
                              rel="nofollow"
                              className=" vf-email-sharing-button vf-shareButton vf-activate-sharing-button"
                            >
                              <i className="icon envelope"></i>
                            </a>
                            <a
                              href="javascript:window.print()"
                              rel="nofollow"
                              className=" vf-print-sharing-button vf-shareButton"
                              aria-label="Print"
                            >
                              <i className="icon print"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  )
}

export default SmartfeedIndividual
