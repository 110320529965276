import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import { serializeNode } from "../utils/wysiwyg"
import fetchJsonp from "fetch-jsonp"

import SmartFeedFiltersComponent from "../components/SmartFeedWithFilters"

import renderHTML from "react-render-html"

require("es6-promise").polyfill()

const getURL = function getURL() {
  return typeof window !== "undefined" ? window.location.href : ""
}

export default function VacanciesListPage({ props }) {
  const [vacancyLoading, setVacancyLoading] = useState(true)

  return (
    <Layout {...props}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Vacancies at Longleat</title>
        <meta
          name="description"
          content="Find your new career at Longleat today"
        />
      </Helmet>

      <div className="home">
        <section className="app-content">
          <div>
            {!vacancyLoading && (
              <div className="app-hero-wrapper app-hero-home app-hero-video">
                <div
                  className="app-hero-background-image"
                  style={{
                    backgroundImage: `url(https://cdn2.accropress.com/c72a1f25-ba07-452f-a7dc-69299837e568/447bf9a0-061d-41f6-8af9-c5c085487bd9.jpeg)`,
                  }}
                ></div>
                <div className="app-hero-background-overlay"></div>
                <div className="app-hero-content-center"></div>
                <div className="app-hero-content-bottom"></div>
              </div>
            )}

            <div className="app-content-content">
              {!vacancyLoading && (
                <div className="content-block-intro">
                  <div className="container">
                    <div className="homepage-opener-section row">
                      <div className="col-xs-12 homepage-opener-section-center">
                        <h1>Vacancies at Longleat</h1>
                        <div className="page-content-wysiwyg"></div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="content-block-light">
                <div className="content-block-vacancies">
                  <div className="container">
                    <SmartFeedFiltersComponent
                      vacancyLoading={vacancyLoading}
                      setVacancyLoading={setVacancyLoading}
                      apiKey={"FNxiW9hQTwcXaAW0rcVo5NSC1ui8BTNfGhk9FN0-uZw"}
                      group={false}
                      gdprKey=""
                      CompanyGroupName="Longleat"
                      VacancyPageBaseURL="https://jobs.longleat.co.uk/vacancies/vacancy/"
                      ReturnToSearchURL="https://jobs.longleat.co.uk/vacancies/"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {vacancyLoading && (
            <div className="app-content-content app-content-content-loading"></div>
          )}
        </section>
      </div>
    </Layout>
  )
}
